<script setup>
import { reactive, onMounted, inject } from 'vue'
import { getDayPages } from '../wikipedia'
const $cookies = inject('$cookies')

const history = reactive([])
const firstDate = new Date('2024-01-07')
onMounted(async () => {
    const DAY = 86400 * 1000
    let date = new Date(Math.floor(Date.now() / DAY) * DAY )
    const scores = $cookies.get('score_history') || {}
    while (date >= firstDate) {
        history.push({
            date,
            url: `?date=${date.toISOString().split('T')[0]}`,
            ...await getDayPages(date.toISOString()),
            ...scores[date.toISOString()],
        });
        date = new Date(date - DAY)
    }
})
</script>
<template>
    <table>
        <tr>
            <th colspan=2>Date</th>
            <th colspan=2>Jeu</th>
            <th colspan=2>Score</th>
        </tr>
        <tr v-for="line in history">
            <td><span v-if="line.steps">🏆</span></td>
            <td><a :href="line.url">{{ line.date.toLocaleDateString() }}</a></td>
            <td><a :href="line.url">de 🏁 {{line.start}}</a></td>
            <td><a :href="line.url">à 🎯 {{line.target}}</a></td>
            <td><span v-if="line.steps">{{ line.steps }} étapes,</span></td>
            <td><span v-if="line.steps">{{ line.score }} points</span></td>
        </tr>
    </table>
</template>
<style>
table {
    width: 100%
}
</style>
